import React from 'react';
import PropTypes from 'prop-types';
import { PartnerLogo, PartnerGroupTooltip } from 'porowneo-ui';
import {
  PromoRow,
  PromoCol,
  ResultButton,
  PromotionText,
  PartnerGroupTooltipBox,
} from '../../styled/containers/Results.styled';
import { saveSaleInfo } from '../../helpers/apiRequests';

const ResultsItem = (props) => {
  const {
    redirectLink,
    partnerName,
    setOrder,
    handleClickBuyOnline,
    promoText,
    total,
    user: {
      locator,
      sessionId,
    },
  } = props;

  return (
    <PromoRow
      order={setOrder}
      resultRow
    >
      <PromoCol
        extraPadding
        displayCol
        md={3}
      >
        <PartnerLogo
          partnerName={partnerName}
          maxWidth="90px"
          maxHeight="42px"
          maxMobileWidth="114px"
          maxMobileHeight="53px"
        />
        <PartnerGroupTooltipBox>
          <PartnerGroupTooltip partner={partnerName} />
        </PartnerGroupTooltipBox>
      </PromoCol>
      <PromoCol
        md={7}
        lg={7}
      >
        <PromotionText>
          {promoText}
        </PromotionText>
      </PromoCol>
      <PromoCol
        extraPadding
        paddingRight
        md={2}
      >
        <ResultButton
          id={`buyButton-${partnerName}`}
          buttonPrimary
          handleClick={() => {
            saveSaleInfo({
              partnerName,
              locator,
              sessionId,
              guid: 'none',
              total,
            });
            handleClickBuyOnline(partnerName, redirectLink);
          }}
        >
          Sprawdź ofertę
        </ResultButton>
      </PromoCol>
    </PromoRow>
  );
};

ResultsItem.defaultProps = {
  partnerName: 'porowneo',
  redirectLink: 'https://porowneo.pl/',
  setOrder: 1,
  promoText: 'Promoted offer',
  user: {},
  total: null,
};

ResultsItem.propTypes = {
  partnerName: PropTypes.string,
  redirectLink: PropTypes.string,
  setOrder: PropTypes.number,
  handleClickBuyOnline: PropTypes.func.isRequired,
  promoText: PropTypes.string,
  user: PropTypes.shape({
    locator: PropTypes.string.isRequired,
    shortLocator: PropTypes.string.isRequired,
    sessionId: PropTypes.string.isRequired,
  }),
  total: PropTypes.number,
};

export default ResultsItem;
