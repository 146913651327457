export const themeVars = {
  colors: {
    primary: '#34BC6E',
    secondary: '#9BA4AD',
    secondaryAccessibility: '#697179',
    error: '#BC3434',
    font: '#334457',
    borderColor: '#E1E5EB',
    background: '#FFFFFF',
    darkBackground: '#BDBDBD',
    greenBackground: '#F7FCF9',
    reedBackground: '#F8F6F8',
    white: '#FFFFFF',
    beige: '#F7FCF9',
    disabled: '#F4F4F4',
    disabledText: '#B2B2B2',
  },
  font: {
    fontFamily: '\'Montserrat\', sans-serif, -apple-system, BlinkMacSystemFont',
    defaultFontSize: '16px',
    defaultLineHeight: '24px',
  },
};

export default themeVars;
