import createReducer from './createReducer';
import {
  EDIT_PARAM,
  RESET_PAYLOAD,
  SET_PAYLOAD,
} from '../actions/payloadActions';

export const initialPayload = {
  step: 1,
  locator: '',
  ownerName: '',
  ownerSurname: '',
  ownerPesel: '',
};

export const editPayloadParam = (state, action) => (
  {
    ...state,
    ...action.payload,
  }
);

export const resetPayload = () => (
  initialPayload
);

export const setPayload = (state, action) => (
  {
    ...initialPayload,
    ...state,
    ...action.payload,
  }
);

const payloadReducer = createReducer(initialPayload, {
  [EDIT_PARAM]: editPayloadParam,
  [RESET_PAYLOAD]: resetPayload,
  [SET_PAYLOAD]: setPayload,
});

export default payloadReducer;
