import { log } from 'loglevel';
import axios from 'axios';
import { sendError, getUrlVars } from 'porowneo-ui';

import { getFullUrlFromSessionStorage } from './urlHelpers';
import {
  getCookieByName,
  getParametersFromUrl,
  getExpireTime,
} from './utils';
import { editUserParam } from '../actions/userActions';

import { utmParametersToSendToDatabase } from '../config/companyConfig';
import { createUrlSaveSaleInfo, axiosConfigWithSession, createUrlCarSession } from '../config/url';

export const sessionCreate = (user, dispatch) => {
  const { expireTime = 0, locator } = user;
  const nowTime = new Date().getTime();
  const parametersToSend = utmParametersToSendToDatabase;
  const ga = getCookieByName('_ga');

  if (locator === '' || nowTime > expireTime) {
    axios.post(createUrlCarSession(), {
      source: 'travel',
      id: null,
      ga,
      referer: getFullUrlFromSessionStorage(),
      ...getParametersFromUrl(getUrlVars, parametersToSend),
    }).then((response) => {
      if (response.status === 200 && response.data.fullLocator !== '') {
        dispatch(editUserParam({
          locator: response.data.fullLocator,
          shortLocator: response.data.shortLocator,
          sessionId: response.data.id,
          expire: response.data.expire,
          expireTime: getExpireTime(response.expire),
        }));
      } else {
        log.debug(response);
      }
    });
  }
};

export const saveSaleInfo = ({
  guid, total, partnerName, locator, sessionId,
}, type = 'buy', product = 'travel') => {
  axios.post(
    createUrlSaveSaleInfo(),
    {
      partnerName,
      locator,
      guid,
      total,
      type,
      product,
    },
    axiosConfigWithSession(sessionId),
  ).then(({ data }) => {
    log.debug(data);
  }).catch((error) => {
    sendError(error, false, {
      method: 'saveSaleInfo',
      component: 'Results',
      url: createUrlSaveSaleInfo(),
    });
  });
};
