import rg4js from 'raygun4js';
import { settingAppVersion } from '../helpers/utils';
import { version } from '../../package.json';

const raygun = () => {
  rg4js('apiKey', 'quGSWo3d4RvU1G8041sbUg');
  rg4js('enableCrashReporting', true);

  rg4js('options', {
    excludedHostnames: ['.local', 'localhost'],
  });
  const appVersion = settingAppVersion(localStorage, version);

  rg4js('setVersion', appVersion);
};

export default raygun;
