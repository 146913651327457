import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Header, FooterV2 as Footer, PrivacyPolicyPopup } from 'porowneo-ui';
import styled from 'styled-components';

import { isRadioZetTheme } from '../config/main';
import { BASE_NAME } from '../config/url';
import { appPropTypes } from '../config/propTypes/appPropTypes';
import Results from './Results';
import Redirect from './Redirect';
import { getBaseName } from '../helpers/utils';
import { sessionCreate } from '../helpers/apiRequests';

export const AppWrapper = styled.div`
  font-family: ${props => props.theme.font.fontFamily};
`;

export const App = (props) => {
  const {
    user,
    user: {
      shortLocator,
    },
    dispatch,
  } = props;

  useEffect(() => {
    if ((!user || user?.locator === '') && dispatch) {
      sessionCreate(user, dispatch);
    }
  }, [user, dispatch]);

  return (
    <AppWrapper>
      <Router basename={getBaseName(window.location.pathname, BASE_NAME)}>
        <Header
          locator={shortLocator}
          hiddenTitle
          locatorMargin
          logoLink="https://porowneo.pl/"
          logoLinkTarget="_blank"
        />

        <Switch>
          <Route
            path="/redirect"
            render={() => (
              <Redirect />
            )}
          />
          <Route render={() => (
            <Results user={user} />
          )}
          />

        </Switch>

        <PrivacyPolicyPopup />

        {
          !isRadioZetTheme && (
            <Footer />
          )
        }
      </Router>
    </AppWrapper>
  );
};

App.propTypes = appPropTypes;

const mapStateToProps = ({ payload, user }) => ({
  step: payload.step,
  user,
  ...payload,
});

export default connect(mapStateToProps)(App);
