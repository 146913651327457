import { sendError } from 'porowneo-ui';
import { BASE_NAME } from '../config/url';

export const getDisplayName = WrappedComponent => WrappedComponent?.displayName || WrappedComponent?.name || 'Component';

export const translateCovers = (cover) => {
  if (typeof cover === 'string' && cover.length > 0) {
    switch (cover) {
    case 'health_care':
      return 'Koszty leczenia';
    case 'luggage':
      return 'Bagaż';
    case 'total':
      return 'Składka';
    default:
      return cover.toUpperCase();
    }
  } else {
    return cover;
  }
};

export const getBaseName = (currentPath, baseNames) => {
  let newBasName = '/';

  if (Array.isArray(baseNames) && baseNames.length > 0) {
    baseNames.forEach((baseName) => {
      if (currentPath.startsWith(baseName)) {
        newBasName = baseName;
      }
    });
  }

  return newBasName;
};

export const isExternalLink = link => link.startsWith('http');

export const isntPorowneoDomain = link => isExternalLink(link) && !(new URL(link).hostname.includes('porowneo'));

export const ctaRedirectUrl = (link) => {
  try {
    if (isntPorowneoDomain(link)) {
      const baseName = getBaseName(window.location.pathname, BASE_NAME);

      return `${baseName}/redirect?url=${encodeURIComponent(link)}`;
    }
  } catch (e) {
    if (sendError) {
      sendError(
        e,
        false,
        { method: 'ctaRedirectUrl', url: link },
        'error',
      );
    }
  }
  return link;
};

export const settingAppVersion = (localStorage, appVersion) => {
  const versionKey = 'version_app';
  const varsionAppFromStorage = localStorage.getItem(versionKey);

  if (typeof varsionAppFromStorage === 'undefined' || varsionAppFromStorage === null || localStorage.version_app !== appVersion) {
    localStorage.clear();
    localStorage.setItem(versionKey, appVersion);
    return localStorage.getItem(versionKey);
  }
  return localStorage.getItem(versionKey);
};

export const setOrderSortedItems = ({ total, details }, { sortedBy, sort }) => {
  if (sort === 'asc') {
    switch (sortedBy) {
    case 'total':
      return total ? total * 100 : 999999;
    default:
      return parseInt(details[sortedBy].split(' ').join(''), 10);
    }
  }
  if (sort === 'desc') {
    switch (sortedBy) {
    case 'total':
      return total ? total * 100 : 0;
    default:
      return parseInt(details[sortedBy].split(' ').join(''), 10);
    }
  }
  return 999999;
};

export const getCookieByName = (name) => {
  // Split cookie string and get all individual name=value pairs in an array
  const cookieArr = document.cookie.split(';');
  // Loop through the array elements
  for (let i = 0; i < cookieArr.length; i += 1) {
    const cookiePair = cookieArr[i].split('=');
    /* Removing whitespace at the beginning of the cookie name
    and compare it with the given string */
    if (name === cookiePair[0].trim()) {
      // Decode the cookie value and return
      return decodeURIComponent(cookiePair[1]);
    }
  }
  // Return null if not found
  return null;
};

export const getParametersFromUrl = (createObjectWithUrlParameters, parametersToFind = []) => {
  const parametersFromUrl = createObjectWithUrlParameters();
  const parametersWithValuesFind = {};
  const parametersFromUrlKeys = Object.keys(parametersFromUrl);

  if (parametersFromUrlKeys.length) {
    if (parametersToFind.length) {
      parametersFromUrlKeys.forEach((parameter) => {
        if (parametersToFind.includes(parameter) && parametersFromUrl[parameter]) {
          parametersWithValuesFind[parameter] = parametersFromUrl[parameter];
        }
      });
    } else {
      parametersFromUrlKeys.forEach((param) => {
        parametersWithValuesFind[param] = parametersFromUrl[param];
      });
    }
  } else {
    return {};
  }

  return parametersWithValuesFind;
};

export const getExpireTime = expire => new Date(new Date().getTime() + (expire * 60 * 1000)).getTime();
