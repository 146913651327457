const { dataLayer } = window;

// eslint-disable-next-line import/prefer-default-export
export const pushInsurerRedirectToDataLayer = (insurer) => {
  dataLayer.push({
    insuranceCompany: insurer,
    formType: 'Travel',
    event: 'clickButtonBuyOnline',
  });
};
