import React from 'react';
import PropTypes from 'prop-types';
import { PartnerLogo, PartnerGroupTooltip } from 'porowneo-ui';
import { translateCovers } from '../../helpers/utils';
import {
  ResultsRow,
  ResultsCol,
  ResultButton,
  CheckedMark,
  CoverName,
  CoverDesc,
  Cover,
  CoverValue,
  ExtraDesc,
  PartnerGroupTooltipBox,
} from '../../styled/containers/Results.styled';
import { saveSaleInfo } from '../../helpers/apiRequests';

const ResultsItem = (props) => {
  const {
    additionalDesc,
    covers,
    coverDetails,
    redirectLink,
    partnerName,
    setOrder,
    handleClickBuyOnline,
    total,
    user: {
      locator,
      sessionId,
    },
  } = props;

  return (
    <ResultsRow
      order={setOrder}
      resultRow
    >
      <ResultsCol
        extraPadding
        displayCol
        md={3}
      >
        <PartnerLogo
          partnerName={partnerName}
          maxWidth="90px"
          maxHeight="42px"
          maxMobileWidth="114px"
          maxMobileHeight="53px"
        />
        <PartnerGroupTooltipBox>
          <PartnerGroupTooltip partner={partnerName} />
        </PartnerGroupTooltipBox>
      </ResultsCol>
      <ResultsCol
        md={6}
        lg={5}
      >
        <ResultsRow
          covers
        >
          {Object.keys(covers).map(cover => (
            <ResultsCol
              key={`${cover}-${partnerName}`}
              covers
              md={6}
            >
              {cover && (
                <Cover>
                  <CheckedMark />
                  <CoverDesc>
                    <CoverName>
                      {translateCovers(cover)}
                    </CoverName>
                    <CoverValue>
                      <span>
                        od
                      </span>
                      <span>
                        {`${coverDetails[cover]} zł`}
                      </span>
                    </CoverValue>
                  </CoverDesc>
                </Cover>
              )}
            </ResultsCol>
          ))}
        </ResultsRow>
      </ResultsCol>
      <ResultsCol
        total
        md={1}
        lg={2}
      >
        {total && (
          <Cover>
            <CoverDesc
              total
              mobilePadding
            >
              <CoverValue>
                <span>
                  <b>
                    {`od ${total} zł`}
                  </b>
                  {' / dzień'}
                </span>
              </CoverValue>
            </CoverDesc>
          </Cover>
        )}
      </ResultsCol>
      <ResultsCol
        extraPadding
        paddingRight
        md={2}
      >
        <ResultButton
          id={`buyButton-${partnerName}`}
          buttonPrimary
          handleClick={() => {
            saveSaleInfo({
              partnerName,
              locator,
              sessionId,
              guid: 'none',
              total,
            });
            handleClickBuyOnline(partnerName, redirectLink);
          }}
        >
          Sprawdź ofertę
        </ResultButton>
      </ResultsCol>
      {additionalDesc !== '' && (
        <ResultsCol
          border
          col
        >
          <ExtraDesc>
            {`Dodatkowo: ${additionalDesc}`}
          </ExtraDesc>
        </ResultsCol>
      )}
    </ResultsRow>
  );
};

ResultsItem.defaultProps = {
  partnerName: 'porowneo',
  covers: {
    health_care: true,
    OC: true,
    NNW: true,
    luggage: true,
  },
  coverDetails: {
    health_care: 'porowneo',
    OC: 'porowneo',
    NNW: 'porowneo',
    luggage: 'porowneo',
  },
  additionalDesc: '',
  redirectLink: 'https://porowneo.pl/',
  user: {},
  setOrder: 1,
  total: null,
};

ResultsItem.propTypes = {
  partnerName: PropTypes.string,
  covers: PropTypes.shape({
    health_care: PropTypes.bool,
    OC: PropTypes.bool,
    NNW: PropTypes.bool,
    luggage: PropTypes.bool,
  }),
  coverDetails: PropTypes.objectOf(PropTypes.string),
  additionalDesc: PropTypes.string,
  redirectLink: PropTypes.string,
  user: PropTypes.shape({
    locator: PropTypes.string.isRequired,
    shortLocator: PropTypes.string.isRequired,
    sessionId: PropTypes.string.isRequired,
  }),
  setOrder: PropTypes.number,
  handleClickBuyOnline: PropTypes.func.isRequired,
  total: PropTypes.number,
};

export default ResultsItem;
