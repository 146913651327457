import React from 'react';
import PropTypes from 'prop-types';
import {
  SortItemsStyled,
  SortItemsContainer,
  SortButton,
  SortRow,
  SortCol,
  PageTitle,
} from '../../styled/components/SortItems.styled';
import { translateCovers } from '../../helpers/utils';

const SortItems = (props) => {
  const {
    handleSort,
    sortBy,
    pageHeader,
  } = props;

  const setOrder = (key) => {
    switch (key) {
    case 'health_care':
      return 1;
    case 'OC':
      return 2;
    case 'NNW':
      return 3;
    case 'luggage':
      return 4;
    default:
      return 999;
    }
  };

  return (
    <SortItemsStyled>
      <SortItemsContainer>
        <SortRow
          header
        >
          <SortCol
            col
            header
          >
            <PageTitle>
              {pageHeader}
            </PageTitle>
          </SortCol>
        </SortRow>
        <SortRow>
          <SortCol
            col
            md={8}
            mdOffset={2}
          >
            {Object.keys(sortBy).map(key => (
              <SortButton
                order={setOrder(key)}
                key={`sortBy-${key}`}
                onClick={() => handleSort(key, sortBy[key])}
                active={sortBy[key].active}
                sort={sortBy[key].sort}
              >
                {translateCovers(key)}
              </SortButton>
            ))}
          </SortCol>
        </SortRow>
      </SortItemsContainer>
    </SortItemsStyled>
  );
};

SortItems.defaultProps = {
  pageHeader: 'Oferty',
};

SortItems.propTypes = {
  handleSort: PropTypes.func.isRequired,
  sortBy: PropTypes.shape({
    active: PropTypes.bool,
    sort: PropTypes.string,
  }).isRequired,
  pageHeader: PropTypes.string,
};

export default SortItems;
