import styled from 'styled-components';
import { rem } from 'polished';
import {
  Container,
  Row,
  Col,
  media,
} from 'styled-bootstrap-grid';
import { Button } from 'porowneo-ui';
import { ReactComponent as CheckMark } from '../../img/checkMark.svg';
import { setOrderSortedItems } from '../../helpers/utils';

export const ResultsStyled = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.disabled};
`;

export const ResultsContainer = styled(Container)`
  min-height: 60vh;
  padding: 24px 31px 48px;

  ${media.md`
    padding-top: 63px;
    padding-bottom: 60px;
  `}
`;

export const PromoRow = styled(Row)`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  background-color: ${props => (!props.noBackground ? props.theme.colors.background : 'transparent')};
  min-height: ${props => (!props.resultRow ? 'auto' : '365px')};
  border-radius: 4px;
  order: ${props => props.order || 999999};

  ${media.md`
    min-height: 80px;
    max-height: 200px;
  `}
`;

export const PromoCol = styled(Col)`
  display: flex;
  flex-direction: ${props => (props.displayCol ? 'column' : 'row')};
  align-items: center;
  justify-content: center;

  ${media.md`
    ${props => props.extraPadding && `
      padding-top: 36px;
      padding-bottom: 23px;
    `}
  `}

  ${media.lg`
    ${props => props.extraPadding && `
        padding-top: 20px;
        padding-bottom: 20px;
    `}
  `}
`;

export const ResultsRow = styled(Row)`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  background-color: ${props => (!props.noBackground ? props.theme.colors.background : 'transparent')};
  min-height: ${props => (!props.resultRow ? 'auto' : '430px')};
  border-radius: 4px;
  order: ${({ order }) => order?.item && setOrderSortedItems(order?.item, order.actualSort)};

  &:nth-child(2) {
    ${media.md`
      border-radius: 0 0 4px 4px;
    `}
  }

  ${props => props.header && `
    display: none;
  `}

  ${props => props.covers && `
    min-height: 80px;
    padding: 0;
    margin: 0;
  `}

  ${props => props.noMarginBottom && `
    margin-bottom: 0;
  `}

  ${media.md`
    min-height: 80px;
    ${props => props.covers && `
      padding-top: 30px;
    `}

    ${props => props.header && `
      display: block;
      min-height: 28px;
      background-color: ${props.theme.colors.reedBackground};
      margin-bottom: 0;
      border-radius: 4px 4px 0 0;
    `}
  `}

  ${media.lg`
    ${props => props.covers && `
        padding-top: 0;
    `}
  `}
`;

export const ResultsCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${props => props.coverBorder && `
    border-bottom: 1px solid ${props.theme.colors.borderColor};
    `}

${props => props.covers && `
    align-items: flex-start;
    justify-content: flex-start;
  `}

  ${props => props.noSidePadding && `
    padding-right: 0;
    padding-left: 0;
  `}

  ${props => props.border && `
    margin-top: 10px;
    padding: 17px 15px 5px;
    border-top: 1px solid ${props.theme.colors.borderColor};
    border-bottom: auto;
  `}

  ${props => props.total && `
    align-items: flex-start;
    justify-content: center;
  `}

  ${media.md`
    ${props => props.total && `
      align-items: left;
    `}
  `}

  ${media.lg`
    ${props => props.extraPadding && `
        padding-top: 10px;
        padding-bottom: 10px;
    `}
    ${props => props.coverBorder && `
      border-bottom: none;
      border-right: 1px solid ${props.theme.colors.borderColor};
    `}
  `}

  ${media.xl`
    ${props => props.covers && `
      justify-content: space-evenly;
    `}
  `}
`;

export const ResultTitle = styled.p`
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: ${rem('11px')};
  line-height: ${rem('24px')};
  letter-spacing: 0.18em;
`;

export const ResultButton = styled(Button)`
  && {
    min-width: 100%;
    padding: 0 15px;
    font-weight: bold;
    white-space: wrap;
    border-radius: 4px;
    margin: 0;
    height: 42px;
    max-height: 48px;
    min-height: 48px;
    text-align: center;
    font-size: ${rem('16px')};
    line-height: ${rem('18px')};
    font-family: ${props => props.theme.font.fontFamily};

    ${props => props.marginBottom && `
      margin-bottom: 12px;
    `}
  }

  ${media.md`
    && {
      min-width: 100%;
      font-size: ${rem('12px')};
      line-height: ${rem('14px')};
      min-width: 100px;

      ${props => props.marginBottom && `
        margin-bottom: 9px;
      `}
    }
  `}

  ${media.xl`
    && {
      height: 42px;
      max-height: 42px;
    }
  `}
`;

export const CheckedMark = styled(CheckMark)`
  min-width: 16px;
  min-height: 16px;
  max-width: 16px;
  max-height: 16px;
  margin-right: 8px;

  ${media.md`
    min-width: 18px;
    min-height: 18px;
    max-width: 18px;
    max-height: 18px;
    margin-right: 13px;
  `}
`;

export const Cover = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: ${rem('12px')};
  line-height: ${rem('18px')};

  ${media.md`
    font-size: ${rem('14px')};
  `};
`;

export const CoverName = styled.p`
  font-weight: bold;

  ${media.lg`
    padding-bottom: 3px;
    white-space: ${props => (!props.wrapText ? 'nowrap' : 'wrap')} ;
  `}
`;

export const CoverValue = styled.div`
  display: flex;
  flex-direction: row;

  span {
    white-space: wrap;
    margin-right: 4px;
  }
`;

export const CoverDesc = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${props => props.mobilePadding && `
    padding-left: 41px;
  `}

  ${media.md`
    min-height: 57px;
    justify-content: ${props => (props.total ? 'center' : 'flex-end')};
    padding: 0;
  `}
`;

export const ResultsExtra = styled.p`
  font-size: ${rem('12px')};
  line-height: ${rem('14px')};
  color: ${props => props.theme.colors.secondaryAccessibility};
  text-align: center;
  padding-top: 30px;
`;

export const ResultsSortContainer = styled.div`
  display: flex;
  flex-direction: ${props => (props.sort === 'desc' ? 'column-reverse' : 'column')};
  width: 100%;
`;

export const PartnerGroupTooltipBox = styled.div`
  margin-top: 16px;
`;

export const ExtraDesc = styled.span`
  color: ${props => props.theme.colors.secondaryAccessibility};
  font-size: ${rem('12px')};
  line-height: ${rem('18px')};
`;

export const PromotionText = styled.p`
  ${media.md && `
    padding-right: 20px;
    padding-left: 10px;
  `}
`;
