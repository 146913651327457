export const xl = 1440;
export const lg = 1080;
export const md = 720;
export const sm = 360;
export const xs = 360;

export const gridTheme = {
  breakpoints: {
    xl,
    lg,
    md,
    sm,
    xs,
  },
  row: {
    padding: 15,
  },
  col: {
    padding: 15,
  },
  container: {
    padding: 15,
    maxWidth: {
      xl: 1200,
      lg: 960,
      md: 720,
      sm: 540,
      xs: 540,
    },
  },
};
