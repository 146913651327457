import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  ResultsContainer,
  ResultsStyled,
  ResultsRow,
  ResultsCol,
  ResultTitle,
  ResultsExtra,
  ResultsSortContainer,
} from '../styled/containers/Results.styled';
import ResultsItem from '../components/results/ResultsItem';
import PromoItem from '../components/results/PromoItem';
import SortItems from '../components/results/SortItems';
import wsResults from '../json/ResultsSample.json';
import wsPromo from '../json/ResultsPromoted.json';
import { resultHeader, resultExtraDesc } from '../config/companyConfig';
import { pushInsurerRedirectToDataLayer } from '../helpers/tagManagerUtils';
import { ctaRedirectUrl } from '../helpers/utils';

class Results extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sorted: {
        health_care: {
          active: false,
          sort: 'asc',
        },
        OC: {
          active: false,
          sort: 'asc',
        },
        NNW: {
          active: false,
          sort: 'asc',
        },
        luggage: {
          active: false,
          sort: 'asc',
        },
        total: {
          active: false,
          sort: 'asc',
        },
      },
      actualSort: {
        default: true,
        sortedBy: 'health_care',
        sort: 'asc',
      },
    };
  }

  handleSetSort = (key, sortBy) => {
    const {
      state: {
        sorted,
      },
    } = this;
    const changeSort = (sortType) => {
      if (sortBy.active) {
        return sortType === 'asc' ? 'desc' : 'asc';
      }
      return sortType;
    };

    const newState = {
      ...sorted,
    };
    Object.keys(newState).forEach((sortedKey) => {
      if (key !== sortedKey) {
        newState[sortedKey] = {
          ...sorted[sortedKey],
          active: false,
        };
      }
    });

    const newSort = changeSort(sortBy.sort);

    this.setState({
      sorted: {
        ...newState,
        [key]: {
          sort: newSort,
          active: true,
        },
      },
      actualSort: {
        default: false,
        sortedBy: key,
        sort: newSort,
      },
    });
  }

  chooseOrderType = (item, { sortedBy, sort }) => {
    if (sortedBy === 'total') {
      if (!item.total && sort === 'asc') {
        return 0;
      }
      if (!item.total && sort === 'desc') {
        return 999999;
      }
      return item.total;
    }
    return item.details[sortedBy];
  }

  handleClickBuyOnline = (partnerName, partnerLink) => {
    pushInsurerRedirectToDataLayer(partnerName.toLowerCase());

    const offerUrl = ctaRedirectUrl(partnerLink);

    window.open(offerUrl);
  }

  setDefaultOrder = (partnerName) => {
    switch (partnerName) {
    case 'Aviva':
      return 1;
    case 'TuEuropa':
      return 2;
    case 'Generali':
      return 3;
    case 'Link4':
      return 4;
    case 'MTU':
      return 5;
    case 'Proama':
      return 6;
    default:
      return 0;
    }
  };

  render() {
    const {
      state: {
        sorted,
        actualSort,
      },
      props: {
        resultsTitle,
        user,
      },
      handleSetSort,
      handleClickBuyOnline,
      setDefaultOrder,
    } = this;

    return (
      <>
        <SortItems
          sortBy={sorted}
          handleSort={handleSetSort}
          pageHeader={resultHeader}
        />
        <ResultsStyled>
          <ResultsContainer>
            <ResultsRow
              header
            >
              <ResultsCol
                col
              >
                <ResultTitle>
                  {resultsTitle}
                </ResultTitle>
              </ResultsCol>
            </ResultsRow>
            <ResultsSortContainer>
              {wsPromo && wsPromo.length > 0 && (
                wsPromo.map(item => (
                  <PromoItem
                    isPromo
                    key={`result-${item.partner_name}`}
                    partnerName={item.partner_name}
                    redirectLink={item.redirectLink}
                    handleClickBuyOnline={handleClickBuyOnline}
                    promoText={item.promoText}
                    total={item.total}
                    user={user}
                    setOrder={item.order}
                  />
                ))
              )}
            </ResultsSortContainer>
            <ResultsSortContainer
              sort={actualSort.sort}
            >
              {wsResults && wsResults.length > 0 && (
                wsResults.map(item => (
                  <ResultsItem
                    key={`result-${item.partner_name}`}
                    partnerName={item.partner_name}
                    covers={item.covers}
                    total={item.total}
                    coverDetails={item.details}
                    additionalDesc={item.otherDesc}
                    redirectLink={item.redirectLink}
                    handleClickBuyOnline={handleClickBuyOnline}
                    user={user}
                    setOrder={
                      actualSort.default ?
                        setDefaultOrder(item.partner_name) :
                        { item, actualSort }
                    }
                  />
                ))
              )}
            </ResultsSortContainer>
            <ResultsRow
              noBackground
              noMarginBottom
            >
              <ResultsCol
                col
              >
                <ResultsExtra>
                  {resultExtraDesc}
                </ResultsExtra>
              </ResultsCol>
            </ResultsRow>
          </ResultsContainer>
        </ResultsStyled>
      </>
    );
  }
}

Results.defaultProps = {
  resultsTitle: 'OFERTA',
  user: {},
};

Results.propTypes = {
  resultsTitle: PropTypes.string,
  user: PropTypes.shape({
    locator: PropTypes.string.isRequired,
    shortLocator: PropTypes.string.isRequired,
    sessionId: PropTypes.string.isRequired,
  }),
};
export default Results;
