import createReducer from './createReducer';
import {
  EDIT_USER_PARAM,
  RESET_USER_PAYLOAD,
} from '../actions/userActions';

export const initialUserPayload = {
  locator: '',
  sessionId: '',
  expire: '',
  expireTime: 0,
  token: '',
  name: '',
  surname: '',
  phoneLeaveContact: '',
};

export const editUserParam = (state, action) => (
  {
    ...initialUserPayload,
    ...state,
    ...action.payload,
  }
);

export const resetUserPayload = () => (
  initialUserPayload
);

const payloadReducer = createReducer(initialUserPayload, {
  [EDIT_USER_PARAM]: editUserParam,
  [RESET_USER_PAYLOAD]: resetUserPayload,
});

export default payloadReducer;
