const BASENAME = [
  '/form',
  '/kalkulator/podroze',
  '/Ubezpieczenia/Turystyczne',
];

export const API_URL = process?.env?.REACT_APP_API_URL ?? 'http://localhost:8080';
export const API_DOCS_URL = process?.env?.REACT_APP_DOCS_API_URL ?? 'http://localhost:3100';

const BASENAME_FROM_ENV = [process?.env?.REACT_APP_BASE_NAME, process?.env?.REACT_APP_BASE_NAME_2];
const filteredBaseNames = BASENAME_FROM_ENV.filter(baseName => !!baseName);

export const BASE_NAME = filteredBaseNames.length > 0 ? filteredBaseNames : BASENAME;

// Session
export const createUrlCarSession = () => `${API_URL}/car/session`;

// Save info about pressed buy online button
export const createUrlSaveSaleInfo = () => `${API_URL}/online-click/save-params`;

// Configs
export const axiosConfigAccountForm = () => ({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json, text/javascript, */*; q=0.01',
  },
});

export const axiosConfigJson = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
  },
};

export const axiosConfigWithSession = sessionId => ({
  ...axiosConfigJson,
  headers: {
    ...axiosConfigJson.headers,
    'session-id': sessionId,
  },
});
