import cloneDeep from 'lodash/cloneDeep';
import { sendError } from 'porowneo-ui';

export const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem('state');
    if (serializedState === null) {
      return {};
    }
    return JSON.parse(serializedState);
  } catch (error) {
    if (sendError) {
      sendError(
        error,
        false,
        { method: 'loadState' },
        'debug',
      );
    }
    return {};
  }
};

export const saveState = (state) => {
  try {
    const stateToStore = cloneDeep(state);
    const serializedState = JSON.stringify(stateToStore);
    sessionStorage.setItem('state', serializedState);
  } catch (error) {
    if (sendError) {
      sendError(
        error,
        false,
        { method: 'saveState' },
        'debug',
      );
    }
  }
};
