/* eslint-disable max-len */
import styled from 'styled-components';
import { rem } from 'polished';
import {
  Container, Col, Row, media,
} from 'styled-bootstrap-grid';

export const SortItemsStyled = styled.div`
  background-color: ${props => props.theme.colors.background};
  width: 100%;
`;

export const SortItemsContainer = styled(Container)`
  padding-top: 25px;
  padding-bottom: 25px;
`;

export const SortRow = styled(Row)`
  padding-top: 24px;
  border-top: 1px solid ${props => props.theme.colors.borderColor};

  ${props => props.header && `
    border: none;
    padding-top: 12px;
    padding-left: 24px;
    padding-right: 24px;
  `}

  ${media.md`
    ${props => props.header && `
      border: none;
      padding-top: 41px;
      padding-left: 15px;
      padding-right: 15px;
    `}
  `}
`;

export const SortCol = styled(Col)`
  display: flex;
  flex-direction: row;
  justify-content: ${props => (!props.header ? 'space-evenly' : 'flex-start')};

  ${props => props.header && `
    padding-left: 0;
    padding-right: 0;
    border-bottom: 1px solid ${props.theme.colors.borderColor};
  `}

  ${media.md`
    border: none;
  `}
`;

export const SortButton = styled.button`
  font-size: ${props => props.theme.font.defaultFontSize};
  line-height: ${props => props.theme.font.defaultLineHeight};
  border: 0;
  font-weight: 400;
  transition: all 0.05s ease-in-out;
  font-style: normal;
  color: ${props => props.theme.colors.font};
  outline: 0;
  cursor: pointer;
  padding-right: 15px;
  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-color: transparent;
  order: ${props => props.order || 1};
  font-family: ${props => props.theme.font.fontFamily};

  ${media.lg`
    margin-right: 50px;
  `}

  ${props => !props.active && `
      color: ${props.theme.colors.secondary};
      filter: grayscale(100%);
  `};

  ${props => props.sort === 'desc' && `
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='11' viewBox='0 0 8 11' fill='none'%3E%3Cpath d='M4.38573 10.5323C4.18574 10.7748 3.81426 10.7748 3.61427 10.5323L0.344665 6.56814C0.0756859 6.24202 0.307659 5.75 0.730392 5.75L7.26961 5.75C7.69234 5.75 7.92431 6.24202 7.65534 6.56814L4.38573 10.5323Z' fill='%2334BC6E'%3E%3C/path%3E%3Crect x='3' width='2' height='6' rx='0.5' fill='%2334BC6E'%3E%3C/rect%3E%3C/svg%3E%0A");
  `}

  ${props => props.sort === 'asc' && `
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' id='Warstwa_1' x='0px' y='0px' width='8px' height='11px' viewBox='0 0 8 11' style='enable-background:new 0 0 8 11;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%2334BC6E;%7D%0A%3C/style%3E%3Cpath class='st0' d='M4.5,10.9l-1,0c-0.3,0-0.5-0.2-0.5-0.5l0-5c0-0.3,0.2-0.5,0.5-0.5h1C4.8,4.9,5,5.1,5,5.4l0,5 C5,10.6,4.8,10.9,4.5,10.9z'%3E%3C/path%3E%3Cpath class='st0' d='M3.6,0.3c0.2-0.2,0.6-0.2,0.8,0l3.3,4c0.3,0.3,0,0.8-0.4,0.8H0.7c-0.4,0-0.7-0.5-0.4-0.8L3.6,0.3z'%3E%3C/path%3E%3C/svg%3E%0A");
    }
  `}
`;
SortButton.displayName = 'SortButton';

export const PageTitle = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: ${rem('20px')};
  line-height: ${rem('24px')};
  padding-bottom: 16px;

  ${media.md`
    font-weight: 600;
    font-size: ${rem('28px')};
    line-height: ${rem('32px')};
    padding-bottom: 33px;
  `}
`;
