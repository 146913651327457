export const companyPhoneNumber = '519 902 560';

export const utmParametersToSendToDatabase = ['utm_source', 'utm_medium', 'utm_campaign', 'sub_id'];

export const resultHeader = 'Porównaj oferty ubezpieczeń turystycznych';

export const companyLinks = [
  {
    address: 'https://porowneo.pl/regulamin/?rule=regulamin_porowneo',
    linkDesc: 'Regulamin',
  },
  {
    address: 'https://porowneo.pl/sitemap',
    linkDesc: 'Mapa strony',
  },
];

export const resultExtraDesc = 'Szczegółowe informacje o zakresie ubezpieczenia, w tym o wyłączeniach i ograniczeniach odpowiedzialności, znajdziesz w aktualnych ogólnych warunkach ubezpieczenia turystycznego, dostępnych na: dla Generali T.U. S.A. na generali.pl, dla Proama (Generali T.U. S.A.) na proama.pl, dla PZU SA. na pzu.pl, dla TU Europa na tueuropa.pl.';
